import React from 'react';
import { Heading, Section, StyledLink } from './Components';

const NotFound = () => {
  return (
    <Section>
      <Heading>
        404: NOT FOUND
      </Heading>
      <StyledLink to={'/'}>
        Go back to the homepage
      </StyledLink>
    </Section>
  )
}

export default NotFound;