import styled from 'styled-components';
import { Link } from 'gatsby';

const Section = styled.section`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Heading = styled.h1`
  color: #FFFFFF;
  text-align: center;
  width: 100%;
`

const StyledLink = styled(Link)`
  color: #FFFFFF;
  font-size: 24px;
  margin-top: 24px;
  padding: 1em;
  border-radius: 12px;
  border: 1px solid #4300D2;
`

export { Heading, Section, StyledLink } 